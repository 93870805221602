body {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #111;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.container {
    text-align: center;
}

.game-container {
    display: flex;
    gap: 5vh;
}

.controls-container {
    display: flex;
    flex-direction: column;
    gap: 1vh;
    justify-content: center;

}

.level-container {
    display: flex;
    flex-direction: column;
}

.score-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3px;
    font-size: 1.5rem;
}

.score-container span {
    color: #52D3D8;
    display: inline-block;
}

.grid-container {
    background-color: #000;
    border: 2px solid #fff;
    border-radius: 5%;
    padding: 20px;
    margin: 10px;
    display: grid;
    grid-template-columns: repeat(10, 7vh);
    grid-template-rows: repeat(10, 7vh);
    gap: 3px;
    place-items: center;
}

.tile-block {
    width: 7vh;
    height: 7vh;
    border-radius: 30%;
    margin: 3px auto;
    z-index: 5;
}

.tile-blink {
    animation: blink-animation 200ms linear infinite;
    /* opacity: 0; */
}

@keyframes blink-animation {
    0% {
        opacity: 0;
    }

    20% {
        opacity: 1;
    }

    40% {
        opacity: 0;
    }

    60% {
        opacity: 1;
    }

    80% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.popup {
    position: fixed;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    font-size: 18px;
    color: #333;
    z-index: 1000;
    animation: fadeInOut 1s ease-out;
}

@keyframes fadeInOut {
    0% {
        opacity: 0;
    }
    20% {
        opacity: 1;
    }
    80% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.loader-container {
    padding-top: 60vh;
    padding-left: 65vh;
}

@media (max-width: 1000px) {
    .grid-container {
        padding: 10px;
        margin: 5px;
        grid-template-columns: repeat(10, 5vh);
        grid-template-rows: repeat(10, 5vh);
    }
    
    .tile-block {
        width: 5vh;
        height: 5vh;
        border-radius: 30%;
        margin: 3px auto;
        z-index: 5;
    }

    .game-container {
        flex-direction: column;
    }

    .controls-container {
        margin-top: 5vh;
        width: 100%;
        flex-direction: row;
    }

    .loader-container {
        padding-top: 45vh;
        padding-left: 50vh;
    }
}


@media (max-width: 600px) {
    .grid-container {
        grid-template-columns: repeat(10, 4vh);
        grid-template-rows: repeat(10, 4vh);
    }
    
    .tile-block {
        width: 4vh;
        height: 4vh;
    }

    .controls-container {
        margin-top: 3vh;
    }

    .loader-container {
        padding-top: 35vh;
        padding-left: 40vh;
    }
}